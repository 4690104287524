<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.sanction") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
              <div class="block-sarche ml-2">
                <div class="header__search">
                  <select-branch
                    size="small"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.branch_id"
                  >
                  </select-branch>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'penalties.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.company_id.show">
                {{ columns.company_id.title }}
              </th>

              <th v-if="columns.branch_id.show">
                {{ columns.branch_id.title }}
              </th>

              <th v-if="columns.department_id.show">
                {{ columns.department_id.title }}
              </th>

              <th v-if="columns.graphic_id.show">
                {{ columns.graphic_id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>
              <th v-if="columns.file.show">
                {{ columns.file.title }}
              </th>

              <th v-if="columns.amount.show">
                {{ columns.amount.title }}
              </th>

              <th v-if="columns.reason_id.show">
                {{ columns.reason_id.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>
              <th v-if="columns.penalty_date.show">
                {{ columns.penalty_date.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.company_id.show">
                <select-company
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.company_id.title"
                  v-model="filterForm.company_id"
                >
                </select-company>
              </th>
              <th v-if="columns.branch_id.show">
                <select-branch
                  size="mini"
                  :placeholder="columns.branch_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.branch_id"
                >
                </select-branch>
              </th>
              <th v-if="columns.department_id.show">
                <select-department
                  size="mini"
                  :placeholder="columns.department_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.department_id"
                >
                </select-department>
              </th>
              <th v-if="columns.graphic_id.show">
                <select-graphic
                  size="mini"
                  :placeholder="columns.graphic_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.graphic_id"
                >
                </select-graphic>
              </th>
              <th v-if="columns.staff_id.show">
                <select-staff
                  size="mini"
                  :placeholder="columns.staff_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.staff_id"
                >
                </select-staff>
              </th>
              <th v-if="columns.file.show"></th>
              <th v-if="columns.amount.show">
                <crm-input
                  size="mini"
                  placeholder="Сумма штрафа"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.amount"
                  :type="'number'"
                ></crm-input>
              </th>
              <th v-if="columns.reason_id.show">
                <select-reason
                  size="mini"
                  :placeholder="columns.reason_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.reason_id"
                >
                </select-reason>
              </th>
              <th v-if="columns.comment.show">
                <crm-input
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.comment.title"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>
              <th v-if="columns.penalty_date.show">
                <crm-date-picker
                  size="mini"
                  :placeholder="columns.penalty_date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.penalty_date"
                ></crm-date-picker>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  size="mini"
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  size="mini"
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="penalty in list"
              :key="penalty.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">
                {{ penalty.id }}
              </td>
              <td v-if="columns.company_id.show">
                {{ penalty.company ? penalty.company.name : "" }}
              </td>

              <td v-if="columns.branch_id.show">
                {{ penalty.branch ? penalty.branch.name : "" }}
              </td>

              <td v-if="columns.department_id.show">
                {{ penalty.department ? penalty.department.name : "" }}
              </td>

              <td v-if="columns.graphic_id.show">
                {{ penalty.graphic ? penalty.graphic.name : "" }}
              </td>

              <td v-if="columns.staff_id.show">
                {{
                  penalty.staff
                    ? penalty.staff.name + " " + penalty.staff.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.file.show">
                <span
                  style="cursor:pointer; font-weight:bold; color:#2b99d9"
                  @click="showPenaltyFile(penalty.id)"
                  >{{ $t("message.file") }}</span
                >
              </td>
              <td v-if="columns.amount.show">
                {{ Number(penalty.amount).toLocaleString("fi-FI") }}
              </td>

              <td v-if="columns.reason_id.show">
                {{ penalty.reason ? penalty.reason.name : "" }}
              </td>

              <td v-if="columns.comment.show">
                {{ penalty.comment }}
              </td>
              <td v-if="columns.penalty_date.show">
                {{ penalty.penalty_date }}
              </td>
              <td v-if="columns.created_at.show">
                {{ penalty.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ penalty.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="penalty"
                  :permissionShow="'penalties.update'"
                  :permissionDestroy="'penalties.destroy'"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>

        <el-dialog
          :title="$t('message.file')"
          :visible.sync="dialogShowFiles"
          width="40%"
          :append-to-body="true"
          ref="dialogShowFiles"
        >
          <div>
            <ul v-for="file in files" :key="file.id" class="file-list">
              <li class="file-list-item">
                <span
                  @click="downloadFiles(file.id, file.penalty_id, file.name)"
                  ><i class="el-icon-document"></i> {{ file.name }}
                  <i class="el-icon-download ml-3" style="color: #4fc514"></i
                ></span>
              </li>
            </ul>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import selectCompany from "@/components/filters/inventory/select-company";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectReason from "@/components/filters/inventory/select-reason";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
//  import axios from 'axios'
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "PenaltyController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    selectStaff,
    selectCompany,
    selectBranch,
    selectDepartment,
    selectReason,
    selectGraphic
  },
  data() {
    return {
      dialogShowFiles: false,
      loadingButton: false
    };
  },
  computed: {
    ...mapGetters({
      list: "penalty/list",
      columns: "penalty/columns",
      pagination: "penalty/pagination",
      statues: "penalty/statues",
      filter: "penalty/filter",
      files: "penalty/files",
      sort: "penalty/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "penalty/index",
      setPagination: "penalty/setPagination",
      updateSort: "penalty/updateSort",
      updateFilter: "penalty/updateFilter",
      updateColumn: "penalty/updateColumn",
      updatePagination: "penalty/updatePagination",
      show: "penalty/show",
      empty: "penalty/empty",
      delete: "penalty/destroy",
      refreshData: "penalty/refreshData",
      getFiles: "penalty/getFiles",
      // removeFile: "penalty/removeFile",
      downloadFile: "penalty/downloadFile"
    }),
    destroy(model) {
      this.delete(model)
        .then(res => {
          this.$alert(res);
          this.fetchData();
        })
        .catch(err => {
          this.$alert(err);
        });
    },
    showPenaltyFile(id) {
      this.getFiles(id).then(res => {
        this.files = res.data.result.data.files;
      });
      this.dialogShowFiles = true;
    },
    downloadFiles(id, penalty_id, name) {
      var data = {
        id: id,
        penalty_id: penalty_id
      };
      return this.downloadFile(data).then(response => {
        let blob = new Blob([response.data]);
        console.log(response.data);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      });
    }
  }
};
</script>
<style lang="scss">
.file-list {
  .file-list-item {
    margin-bottom: 5px;
    span {
      font-weight: bold;
      font-size: 16px;
      color: #2b99d9;
      display: flex;
      align-items: center;
      gap: 5px;
      i {
        font-weight: bold !important;
        font-size: 16px !important;
      }
      //   color: rgb(93, 198, 255);
    }
  }
}
</style>
